<template>
    <h1 class="page-header">
        {{ $t( "pages.padi.pageHeadline" ) }}
    </h1>

    <p class="body-text">
        {{ $t( "pages.padi.paragraph1" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.padi.paragraph2" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.padi.paragraph3" ) }}
    </p>
    <p class="body-text">
        {{ $t( "pages.padi.paragraph4" ) }}
    </p>

    <h3 class="page-sub-header">
        <n-icon class="page-header-icon">
            <education-icon />
        </n-icon>
        {{ $t( "pages.padi.coursesHeadline" ) }}
    </h3>
    <p class="body-text">
        {{ $t( "pages.padi.course1" ) }}<br>
        {{ $t( "pages.padi.course2" ) }}<br>
        {{ $t( "pages.padi.course3" ) }}<br>
        {{ $t( "pages.padi.course4" ) }}<br>
        {{ $t( "pages.padi.course5" ) }}
    </p>
    <div class="d-flex flex-row justify-content-end">
        <n-button
            ghost
            type="primary"
            @click.prevent.stop="linkTo('contact')"
        >
            <n-icon class="me-2">
                <link-icon />
            </n-icon>
            {{ $t( "buttons.bookNow" ) }}
        </n-button>
    </div>
</template>

<script>
import { Education as EducationIcon, ArrowRight as LinkIcon } from '@vicons/carbon'

export default {
    components: { EducationIcon, LinkIcon },
    methods: {
        linkTo( name ) {
            return this.$helper.routerJumpTo( this, name )
        }
    }
}
</script>

<style scoped>

</style>